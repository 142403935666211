import { serialize } from './support'

export const ajaxFilter = () => {
  /**
   * Ajax filters
   */
  const ajaxFilters = document.querySelectorAll('[data-ajax="filter"]');
  const ajaxIUpdateArea = document.querySelector('[data-ajax="update"]');

  const ajaxFetch = (url) => {

    const xmlhttp = new XMLHttpRequest();

    ajaxIUpdateArea.classList.add('is-loading');

    xmlhttp.onreadystatechange = function () {
      if (xmlhttp.readyState === XMLHttpRequest.DONE) {   // XMLHttpRequest.DONE == 4
        if (xmlhttp.status === 200) {

          const parser = new DOMParser();
          const htmlDocument = parser.parseFromString(xmlhttp.response, "text/html");

          setTimeout(function () {
            ajaxIUpdateArea.innerHTML = htmlDocument.documentElement.querySelector('[data-ajax="update"]').innerHTML;
          }, 600);

          setTimeout(function () {
            ajaxIUpdateArea.classList.remove('is-loading');
          }, 600);

          window.history.pushState('', '', url);

        } else if (xmlhttp.status === 400) {
          console.warn('There was an error 400');
        } else {
          console.warn(xmlhttp.status);
        }
      }
    };

    xmlhttp.open('GET', url, true);

    xmlhttp.send();

  };

  document.addEventListener('click', function (e) {

    if (e.target.matches('.pagination a')) {
      e.preventDefault();
      ajaxFetch(e.target.getAttribute('href'));
    }

  });

  ajaxFilters.forEach(function (filter) {

    let buttonRefresh = filter.querySelector('[data-ajax="filter"] button');
    buttonRefresh.parentNode.removeChild(buttonRefresh);

    filter.addEventListener('change', function (e) {

      let params = serialize(filter);

      if (params.length > 0) {
        params = filter.getAttribute('action').indexOf('?') > -1 ? '&' + params : '?' + params;
      } else {
        params = '';
      }

      ajaxFetch(filter.getAttribute('action') + params);

    });
  });
}
