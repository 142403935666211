export const mobileNav = () => {
  /**
   * Header and navigation hooks
   */
  const nav = document.querySelector('.main-menu');
  const navToggle = document.querySelector('.site-header__toggle-menu');
  const root = document.documentElement;
  const body = document.body;
  const breakpoint = 1001;

  if (window.innerWidth < breakpoint) {
    nav.setAttribute('aria-hidden', true);
  }

  navToggle.addEventListener('click', () => {
    nav.classList.toggle('is-open');
    navToggle.classList.toggle('is-active');
    document.querySelector('body').classList.toggle('has-open-panel');

    if (!navToggle.classList.contains('is-active')) {
      nav.setAttribute('aria-hidden', true);

      const scrollY = body.style.top;
      body.style.position = '';
      body.style.top = '';
      window.scrollTo(0, parseInt(scrollY || '0') * -1);
      root.classList.add('smooth-scroll');
    } else {
      nav.setAttribute('aria-hidden', false);

      const scrollY = document.documentElement.style.getPropertyValue('--scroll-y');
      body.style.position = 'fixed';
      body.style.top = `-${scrollY}`;
      root.classList.remove('smooth-scroll');
    }
  });

  window.addEventListener('scroll', () => {
    document.documentElement.style.setProperty('--scroll-y', `${window.scrollY}px`);
  });

  window.addEventListener('resize', () => {
    if (window.innerWidth >= breakpoint && navToggle.classList.contains('is-active')) {
      const click = new Event('click');
      navToggle.dispatchEvent(click);
    }
  });
}
