/**
 * Vendors
 */
import 'picturefill'
import 'lazysizes'
import './vendor/ls.object-fit'
import EmblaCarousel from 'embla-carousel'

/**
 * Import Functions
 */
import './functions/formValidation'
import './functions/cookieController';
import './functions/cookieControllerSettings'

import { global, accessibility } from './functions/global'
import { sectorSelect } from './functions/sectorSelect'
import { navMain } from './functions/navMain'
import { mobileNav } from './functions/mobileNav'
import { countUp } from './functions/countUp'
import { infield } from './functions/infield'
import { carousels } from './functions/carousels'
import { ajaxFilter } from './functions/ajaxFilter'
import { forms } from './functions/forms'

/**
 * Call Functions
 */
global()
accessibility()
sectorSelect()
carousels(EmblaCarousel)
infield()
countUp()
navMain()
mobileNav()
ajaxFilter()
forms()


