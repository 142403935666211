export const infield = () => {
  /**
   * InField Labels
   */
  const inFieldLabels = document.querySelectorAll('[data-infield="true"]');

  for (let i = 0; i < inFieldLabels.length; i++) {
    const control = inFieldLabels[i].closest('.js-field').querySelector('.js-field-control');

    if (control.value !== '') {
      inFieldLabels[i].classList.add('is-floated');
    }

    control.addEventListener('focus', () => {
      inFieldLabels[i].classList.add('is-floated');
    });

    control.addEventListener('blur', () => {
      if (control.value === '') {
        inFieldLabels[i].classList.remove('is-floated');
      }
    });
  }
}
