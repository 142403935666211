import { scrollToElem } from './support'

export const forms = () => {

  // Form Validation
  let recaptcha = false;

  document.querySelectorAll('.js-validate').forEach(form => {

    if (form.querySelector('.g-recaptcha')) {
      recaptcha = document.querySelector("meta[name='google_recaptcha_site_key']").getAttribute('content');
    }

    new Validate(form, {
      recaptchaSiteKey: recaptcha,
      fieldFeedbackSelector: '.js-field-feedback span',
      ajax: true,
      ajaxSuccessCallback : (form) => {
      },
      ajaxErrorCallback : (form) => {
      },
    });

    // Focus invalid field behaviour
    form.addEventListener('submit', function () {
      let invalidField = form.querySelector('.js-field.is-invalid');
      if (invalidField) {
        scrollToElem(invalidField);
      }
    });
  });

}
