const dataLayer = window.dataLayer = window.dataLayer || [];

const cookieController = new CookieController({
  layout: 'modal',
  requiredCookies: ['PHPSESSID'],
  tooltipContent: 'These cookies are necessary for the website to function and cannot be switched off in our systems.',
  websiteName: 'Norse Consulting',
  optionalGroups: [
    {
      name: 'functional',
      label: 'Functional',
      description: 'Functionality cookies allow the website to remember the user’s site preferences and choices they make on the site when using features such as maps. Functionality cookies can include first party, third party, persistent or session cookies. Similar to strictly necessary cookies, functionality cookies are used to provide a service on the website.',
      cookies: ['CONSENT'],
      defaultState: 'off',
      recommendedState: 'on',
      onAllow: function () {
        dataLayer.push({
          'event': 'ccFunctional',
          'allow': 'allow',
        })
      },
      onDisallow: function () {
        dataLayer.push({
          'event': 'ccFunctional',
          'allow': '',
        })
      },
    },
    {
      name: 'analytics',
      label: 'Analytics and Performance',
      description: 'These cookies allow us to count visits and traffic sources, so we can measure and improve the performance of our site. This includes cookies from third-party analytics services. They help us know which pages are the most and least popular and see how visitors move around the site. All information these cookies collect is aggregated and therefore anonymous. If you do not allow these cookies, we will not know when you have visited our site.',
      cookies: ['_ga', '_gid'],
      defaultState: 'off',
      recommendedState: 'on',
      onAllow: function () {
        dataLayer.push({
          'event': 'ccAnalytics',
          'allow': 'allow',
        })
      },
      onDisallow: function () {
        dataLayer.push({
          'event': 'ccAnalytics',
          'allow': '',
        })
      },
    },
    {
      name: 'marketing',
      label: 'Marketing',
      description: 'These cookies track your online activity to help deliver more relevant advertising or to limit how many times you see an ad. These cookies can share that information with other organizations or advertisers. These are persistent cookies and can be set by third party websites and do things like measure how you view videos that are on our website.',
      cookies: [],
      defaultState: 'off',
      recommendedState: 'on',
      onAllow: function () {
        dataLayer.push({
          'event': 'ccMarketing',
          'allow': 'allow',
        })
      },
      onDisallow: function () {
        dataLayer.push({
          'event': 'ccMarketing',
          'allow': '',
        })
      },
    },
  ],
})
