export const sectorSelect = () => {

  const wrapper = document.querySelector('.sector-select')
  const controls = document.querySelectorAll('[data-controls="sectors"]')
  const panels = document.querySelectorAll('[role="tabpanel"]')
  const tabs = document.querySelectorAll('[role="tab"]')
  const icons = document.querySelectorAll('.sector-select__inner img')

  if (!controls) return

  let tabSelect = function (event) {

    if (!event.target.matches('[role="tab"]')) return

    // Deselect
    for (let i = 0; i < tabs.length; i++) {

      tabs[i].setAttribute('aria-selected', 'false')
    }
    for (let i = 0; i < panels.length; i++) {

      panels[i].setAttribute('aria-expanded', 'false')
    }
    for (let i = 0; i < icons.length; i++) {

      icons[i].setAttribute('aria-hidden', 'true')
    }

    wrapper.setAttribute('data-sector', 'none')

    const sector = event.target.getAttribute('aria-controls')

    // Select
    wrapper.setAttribute('data-sector', sector)
    event.target.setAttribute('aria-selected', sector)
    document.querySelector('[data-panel="' + sector + '"]').setAttribute('aria-expanded', 'true')
    document.querySelector('[data-icon="' + sector + '"]').setAttribute('aria-hidden', 'false')
  }

  document.addEventListener('keydown', tabSelect, false)
  document.addEventListener('click', tabSelect, false)

}
