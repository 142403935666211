export const countUp = () => {

  let countupEls = document.querySelectorAll('.statistics__number');

  if (countupEls.length === 0) {
    return;
  }

  const animationDuration = 2000
  const frameDuration = 1000 / 60
  const totalFrames = Math.round(animationDuration / frameDuration)
  const easeOutQuad = t => t * (2 - t)

  const animateCountUp = el => {
    let frame = 0
    const countTo = parseInt(el.getAttribute('data-total'), 10)

    const counter = setInterval(() => {
      frame++
      const progress = easeOutQuad(frame / totalFrames)
      const currentCount = Math.round(countTo * progress)

      if (parseInt(el.innerHTML, 10) !== currentCount) {
        el.innerHTML = currentCount
      }

      if (frame === totalFrames) {
        clearInterval(counter)
      }
    }, frameDuration)
  }

  const stats = document.querySelector('.statistics')

  let observer = new IntersectionObserver((entry, observer) => {
    countupEls.forEach(animateCountUp)
  })

  observer.observe(stats)

}
