export const global = () => {
  /**
   * Global settings
   */
  // Preload and No-JS
  setTimeout(function () {
    document.documentElement.classList.remove('preload')
    document.documentElement.classList.remove('no-js')
  }, 300)
}

export const accessibility = () => {
  /**
   * Toggle focus on when users are tabbing, and turn them off once
   * they begin clicking again
   */
  const handleFirstTab = (e) => {
    if (e.keyCode === 9) {
      document.body.classList.add('has-tabbing-user')

      window.removeEventListener('keydown', handleFirstTab)
      window.addEventListener('mousedown', handleMouseDownOnce)
    }
  }

  const handleMouseDownOnce = () => {
    document.body.classList.remove('has-tabbing-user')

    window.removeEventListener('mousedown', handleMouseDownOnce)
    window.addEventListener('keydown', handleFirstTab)
  }

  window.addEventListener('keydown', handleFirstTab)
}
