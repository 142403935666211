((window, document, undefined) => {
    let recaptchaLoaded = false;
    let submitter = null;

    const defaults = {
        // Selectors
        alertSelector: '.js-alert',
        alertList: '#alert-list',
        alertTemplate: '.js-alert-template',
        fieldSelector: '.js-field',
        fieldControlSelector: '.js-field-control',
        fieldFeedbackSelector: '.js-field-feedback',

        // AJAX
        ajax: false,
        ajaxSuccessCallback: null, // ajaxSuccessCallback(form) {},
        ajaxErrorCallback: null, // ajaxErrorCallback(form) {},

        // Animation Functions
        animationShowFeedback: null, // animationShowFeedback(feedback) {},
        animationHideFeedback: null, // animationHideFeedback(feedback) {},
        animationShowAlert: null, // animationShowAlert(alert) {},
        animationHideAlert: null, // animationHideAlert(alert, done) {},

        // reCAPTCHA Settings
        recaptchaSiteKey: null,

        // Pass in object of custom validations
        customValidations: {},

        // Localisation for translation support
        localisation: {
            validity: {
                valueMissing: 'This is a required field',
                typeMismatchEmail: 'Please enter a valid email address',
                typeMismatchUrl: 'Please enter a valid website address',
                tooShort: 'Must be a minimum of $0 characters',
                tooLong: 'Must be a maximum of $0 characters',
                rangeUnderflow: 'Must be above $0',
                rangeOverflow: 'Must not exceed $0',
                patternMismatch: 'Please match the format requested',
                default: 'There was a problem with the inputted data',
                multicheckMissing: 'Please select an option',
                multicheckMin: 'Please select a minimum of $0 options',
                multicheckMax: 'Please select a maximum of $0 options',
            },
            alerts: {
                recaptchaExpired: 'reCAPTCHA has expired, please try again',
                recaptchaProblem: 'There is a problem with reCAPTCHA, please try again',
                submissionProblem: 'There was a problem submitting the form, please try again',
                submissionSuccess: 'The form was submitted successfully',
            },
        },
    };


    /**
     * Helper functions
     * @private
     */
    function deepExtend(out, ...objects) {
        out = out || {};

        for (let i = 0; i < objects.length; i++) {
            const obj = objects[i];

            if (!obj) {
                continue;
            }

            for (let key in obj) {
                if (obj.hasOwnProperty(key)) {
                    if (typeof obj[key] === 'object') {
                        out[key] = deepExtend(out[key], obj[key]);
                    } else {
                        out[key] = obj[key];
                    }
                }
            }
        }

        return out;
    }


    /**
     * Asynchronously load scripts in with a callback
     * @param {String} source URL of script file
     * @param {Function} loadCallback On load callback
     * @param {Function} errorCallback On error callback
     */
    function getScript(source, loadCallback, errorCallback) {
        let script = document.createElement('script');
        const prior = document.getElementsByTagName('script')[0];
        script.async = 1;

        script.onload = script.onreadystatechange = (_, isAbort) => {
            if(isAbort || !script.readyState || /loaded|complete/.test(script.readyState)) {
                script.onload = script.onreadystatechange = null;
                script = undefined;

                if(!isAbort && loadCallback) {
                    loadCallback();
                }
            }
        };

        if (errorCallback) {
            script.onerror = () => {
                errorCallback();
            }
        }

        script.src = source;
        prior.parentNode.insertBefore(script, prior);
    }


    /**
     * Serialize all form data into a query string
     * @param  {Node} form The form to serialize
     * @return {String} The serialized form data
     */
    function serialize(form) {
        // Setup our serialized data
        const serialized = [];

        // Loop through each field in the form
        for (let i = 0; i < form.elements.length; i++) {
            const field = form.elements[i];

            // Don't serialize fields without a name, submits, buttons, file and reset inputs, and disabled fields
            if (!field.name || field.disabled || field.type === 'file' || field.type === 'reset' || field.type === 'submit' || field.type === 'button') continue;

            // If a multi-select, get all selections
            if (field.type === 'select-multiple') {
                for (let n = 0; n < field.options.length; n++) {
                    if (!field.options[n].selected) continue;
                    serialized.push(encodeURIComponent(field.name) + "=" + encodeURIComponent(field.options[n].value));
                }
            }

            // Convert field data to a query string
            else if ((field.type !== 'checkbox' && field.type !== 'radio') || field.checked) {
                serialized.push(encodeURIComponent(field.name) + "=" + encodeURIComponent(field.value));
            }
        }

        return serialized.join('&');
    };


    /**
     * Polyfill closest
     */
    if (!Element.prototype.matches) {
        Element.prototype.matches = Element.prototype.msMatchesSelector || Element.prototype.webkitMatchesSelector;
    }

    if (!Element.prototype.closest) {
        Element.prototype.closest = function (selector) {
            let el = this;

            do {
                if (el.matches(selector)) {
                    return el
                };

                el = el.parentElement || el.parentNode;
            } while (el !== null && el.nodeType === 1);

            return null;
        };
    }


    /**
     * Polyfill CustomEvent for IE
     */
    (function () {
        if (typeof window.CustomEvent === 'function') {
            return false;
        }

        function CustomEvent(event, params) {
            params = params || { bubbles: false, cancelable: false, detail: null };
            const evt = document.createEvent('CustomEvent');
            evt.initCustomEvent(event, params.bubbles, params.cancelable, params.detail);
            return evt;
        }

        window.CustomEvent = CustomEvent;
    })();


    /**
     * Validate Constructor
     * @param {Object} form The form element
     * @param {Object} options User options
     */
    function Validate(form, options) {
        // Merge defaults and user options
        this.defaults = defaults;
        this.settings = deepExtend({}, this.defaults, options);

        // Elements
        this.form = form;
        this.controls = form.querySelectorAll(this.settings.fieldControlSelector);
        this.recaptchaRendered = false;

        if (!document.querySelector(this.settings.alertList)) {
            console.warn('Validate.js requires an \'alert list\' element to correctly add reCAPTCHA alerts, please check your configuration.');
        }

        if (!document.querySelector(this.settings.alertTemplate)) {
            console.warn('Validate.js requires an \'alert template\' element to correctly add reCAPTCHA alerts, please check your configuration.');
        }

        if (this.settings.recaptchaSiteKey && !this.form.querySelector('.g-recaptcha')) {
            console.warn('This instance of Validate is configured to use reCAPTCHA, but no `.g-recaptcha` element was found. Please check your configuration.');
        }

        this.init();
    }


    /**
     * Validate Prototype
     * @public
     */
    Validate.prototype = {
        init() {
            /*
            this.form.setAttribute('novalidate', 'novalidate');

            // Setup aria attributes on inputs - TODO: Support DOM changes and
            // automatically update
            for (let i = 0; i < this.controls.length; i++) {
                if (this.controls[i].attributes && this.controls[i].attributes['required']) {
                    this.controls[i].setAttribute('aria-required', true);
                }

                if (this.controls[i].closest('.js-field.is-invalid')) {
                    this.controls[i].setAttribute('aria-invalid', true);
                } else {
                    this.controls[i].setAttribute('aria-invalid', false);
                }
            }
            */

            this.addEventListeners();

            /*
            // Focus first invalid on initialisation for any existing errors
            this.focusFirstInvalid();
            */
        },

        loadRecaptcha() {
            if (this.settings.recaptchaSiteKey) {
                if (!recaptchaLoaded) {
                    window.recaptchaCallback = this.recaptchaCallback;
                    window.currentValidateInstance = this;
                    getScript('https://www.google.com/recaptcha/api.js?onload=recaptchaCallback&render=explicit', null, () => {
                        this.addAlert(this.settings.localisation.alerts.recaptchaProblem, 'invalid');
                    });
                } else {
                    this.recaptchaCallback(this);
                }
            }
        },

        addEventListeners() {
            /*
            const firstControlFocus = (e) => {
                if (e.target && e.target.matches(this.settings.fieldControlSelector)) {
                    this.loadRecaptcha();
                    this.form.removeEventListener('focus', firstControlFocus, true);
                }
            };

            // Use delegated events so we don't have to manage DOM changes and
            // updating event listeners
            this.form.addEventListener('focus', firstControlFocus, true);
            this.form.addEventListener('change', (e) => {
                if (e.target && e.target.matches(this.settings.fieldControlSelector)) {
                    this.validateControl(e.target);
                }
            });
            */

            this.form.addEventListener('submit', (e) => {
                e.preventDefault();

                // Update controls NodeList in case form DOM has changed
                this.controls = this.form.querySelectorAll(this.settings.fieldControlSelector);
                for (let i = 0; i < this.controls.length; i++) {
                    this.validateControl(this.controls[i]);
                }

                /*
                const invalidFields = this.form.querySelectorAll(`${this.settings.fieldSelector}.is-invalid`);

                if (invalidFields.length) {
                    invalidFields[0].querySelector('input, select, textarea').focus();
                    submitter = null;
                } else {
                    if (e.submitter) {
                        submitter = e.submitter;
                    }

                    if (this.settings.recaptchaSiteKey && !this.recaptchaRendered) {
                        const recaptchaLoadHandler = () => {
                            this.form.dispatchEvent(new Event('submit', { cancelable: true }));

                            this.form.removeEventListener('recaptcha_loaded', recaptchaLoadHandler);
                        };
                        this.form.addEventListener('recaptcha_loaded', recaptchaLoadHandler);

                        this.loadRecaptcha();

                        return false;
                    }

                    const recaptchaEl = this.form.querySelector('.g-recaptcha')
                    if (this.settings.recaptchaSiteKey && recaptchaEl) {
                        if (typeof grecaptcha === 'undefined') {
                            // If reCAPTCHA isn't present, it's either blocked by the user or there's
                            // an issue with the CDN. Handle custom logic here, i.e. show an alert
                            // indicating the user may need to try again
                            this.addAlert(this.settings.localisation.alerts.recaptchaProblem, 'invalid');
                            return false;
                        }

                        grecaptcha.execute(parseInt(recaptchaEl.getAttribute('data-recaptcha-id')));
                    } else {
                        // Doesn't use reCAPTCHA, bypass reCAPTCHA check manually
                        this.form.dispatchEvent(new CustomEvent('recaptcha_passed'));
                    }
                }
                */

                if (e.submitter) {
                    submitter = e.submitter;
                }

                if (this.settings.recaptchaSiteKey && !this.recaptchaRendered) {
                    const recaptchaLoadHandler = () => {
                        this.form.dispatchEvent(new Event('submit', { cancelable: true }));

                        this.form.removeEventListener('recaptcha_loaded', recaptchaLoadHandler);
                    };
                    this.form.addEventListener('recaptcha_loaded', recaptchaLoadHandler);

                    this.loadRecaptcha();

                    return false;
                }

                const recaptchaEl = this.form.querySelector('.g-recaptcha')
                if (this.settings.recaptchaSiteKey && recaptchaEl) {
                    if (typeof grecaptcha === 'undefined') {
                        // If reCAPTCHA isn't present, it's either blocked by the user or there's
                        // an issue with the CDN. Handle custom logic here, i.e. show an alert
                        // indicating the user may need to try again
                        this.addAlert(this.settings.localisation.alerts.recaptchaProblem, 'invalid');
                        return false;
                    }

                    grecaptcha.execute(parseInt(recaptchaEl.getAttribute('data-recaptcha-id')));
                } else {
                    // Doesn't use reCAPTCHA, bypass reCAPTCHA check manually
                    this.form.dispatchEvent(new CustomEvent('recaptcha_passed'));
                }
            });

            this.form.addEventListener('recaptcha_passed', () => {
                // If the submit button has a name and a value (e.g. if there are
                // multiple submit buttons), create a hidden input and apply its value
                let submitterInput = null;
                if (submitter && submitter.name && submitter.value) {
                    submitterInput = document.createElement('input');
                    submitterInput.type = 'hidden';
                    submitterInput.name = submitter.name;
                    submitterInput.value = submitter.value;
                    this.form.appendChild(submitterInput);
                }

                if (this.settings.ajax === true) {
                    const request = new XMLHttpRequest();
                    let action;

                    if (submitter && submitter.formAction) {
                        action = submitter.formAction;
                    } else {
                        action = this.form.action || this.form.getAttribute('action') || location.href;
                    }

                    request.open('POST', action, true);
                    request.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8');
                    request.setRequestHeader('X-Requested-With','XMLHttpRequest');

                    if (submitter && submitter.classList) {
                        submitter.classList.add('is-loading');
                    }

                    request.onload = () => {
                        // Remove existing alerts
                        const alerts = document.querySelectorAll('.flash-message:not(.js-alert-template)');
                        for (let i = 0; i < alerts.length; i++) {
                            this.removeAlert(alerts[i]);
                        }

                        const responseJSON = JSON.parse(request.response);

                        if (submitter && submitter.classList) {
                            submitter.classList.remove('is-loading');
                        }

                        if (request.status >= 200 && request.status < 400) {
                            // Success handler
                            this.addAlert(responseJSON.message || this.settings.localisation.alerts.submissionSuccess, 'valid');

                            // Reset form, clear any submitter stuff
                            this.form.reset();
                            submitter = null;
                            if (submitterInput) {
                                submitterInput.parentNode.removeChild(submitterInput);
                            }

                            const recaptchaEl = this.form.querySelector('.g-recaptcha')
                            if (this.settings.recaptchaSiteKey && recaptchaEl) {
                                grecaptcha.reset(parseInt(recaptchaEl.getAttribute('data-recaptcha-id')));
                            }

                            if (typeof this.settings.ajaxSuccessCallback === 'function') {
                                this.settings.ajaxSuccessCallback.call(this, this.form);
                            }
                        } else {
                            // Non-error handler
                            /*
                            const fields = this.form.querySelectorAll(this.settings.fieldSelector);

                            fields.forEach(field => {
                                this.setValidState(field);
                            });
                            */

                            // Error handler
                            const recaptchaEl = this.form.querySelector('.g-recaptcha')
                            if (this.settings.recaptchaSiteKey && recaptchaEl) {
                                grecaptcha.reset(parseInt(recaptchaEl.getAttribute('data-recaptcha-id')));
                            }

                            this.addAlert(responseJSON.message || this.settings.localisation.alerts.submissionProblem, 'invalid')
                            const errors = responseJSON.errors;

                            for (let key in errors) {
                                const input = this.form.querySelector(`[name="${key}"]`);

                                if (input) {
                                    const field = input.closest(this.settings.fieldSelector);

                                    if (field) {
                                        this.setInvalidState(field, errors[key][0]);
                                    }
                                }
                            }

                            this.focusFirstInvalid();

                            // Clear any submitter stuff
                            submitter = null;
                            if (submitterInput) {
                                submitterInput.parentNode.removeChild(submitterInput);
                            }

                            if (typeof this.settings.ajaxErrorCallback === 'function') {
                                this.settings.ajaxErrorCallback.call(this, this.form);
                            }
                        }
                    };

                    request.onerror = () => {
                        this.addAlert(this.settings.localisation.alerts.submissionProblem, 'invalid');
                    };

                    request.send(serialize(this.form));
                } else {
                    // If the submit button has a formaction="" attribute, manually update
                    // the form's action here
                    if (submitter && submitter.formAction) {
                        this.form.action = submitter.formAction;
                    }

                    // Unset submitter to prevent any weirdness if the form hangs and
                    // a separate form is clicked, for example
                    submitter = null;
                    this.form.submit();
                }
            });

            this.form.addEventListener('recaptcha_error', () => {
                this.addAlert(this.settings.localisation.alerts.recaptchaProblem, 'invalid');
            });

            this.form.addEventListener('recaptcha_expired', () => {
                this.addAlert(this.settings.localisation.alerts.recaptchaExpired, 'invalid');
            });

            const alerts = document.querySelectorAll(this.settings.alertSelector);

            for (let i = 0; i < alerts.length; i++) {
                alerts[i].querySelector('.js-alert-close').addEventListener('click', () => {
                    this.removeAlert(alerts[i]);
                });
            }
        },

        focusFirstInvalid() {
            const invalidFields = this.form.querySelectorAll(`${this.settings.fieldSelector}.is-invalid`);
            if (invalidFields.length) {
                invalidFields[0].querySelector('input, select, textarea').focus();
            }
        },

        /**
         * @param {Object} validateInstance
         */
        recaptchaCallback(validateInstance) {
            /**
             * This callback needs to be first called from the global scope, so
             * does not have access to the current instance of Validate, so a
             * global variable is set or passed in as argument
             */
            let base;
            if (typeof validateInstance === 'undefined') {
                base = window.currentValidateInstance;
            } else {
                base = validateInstance;
            }
            recaptchaLoaded = true;

            const recaptcha = base.form.querySelector('.g-recaptcha');

            if (typeof grecaptcha !== 'undefined') {
                const widgetId = grecaptcha.render(recaptcha, {
                    sitekey: base.settings.recaptchaSiteKey,
                    size: 'invisible',
                    callback(token) {
                        base.form.dispatchEvent(new CustomEvent('recaptcha_passed'));
                    },
                    'error-callback': () => {
                        base.form.dispatchEvent(new CustomEvent('recaptcha_error'));
                    },
                    'expired-callback': () => {
                        base.form.dispatchEvent(new CustomEvent('recaptcha_expired'));
                    },
                });

                recaptcha.setAttribute('data-recaptcha-id', widgetId);
                base.recaptchaRendered = true;
            } else {
                base.addAlert(this.settings.localisation.alerts.recaptchaProblem, 'invalid');
            }
            base.form.dispatchEvent(new CustomEvent('recaptcha_loaded'));

            // Remove global pollution
            window.recaptchaCallback = null;
            window.currentValidateInstance = null;

        },

        /**
         * @param {String} message
         * @param {String} level
         */
        addAlert(message, level) {
            const alertList = document.querySelector(this.settings.alertList);
            const alert = document.querySelector(this.settings.alertTemplate).cloneNode(true);
            const alertClose = alert.querySelector('.js-alert-close');

            alert.classList.remove('js-alert-template');
            alert.classList.add(`alert-${level}`);
            alert.style.display = 'block';

            alertList.appendChild(alert);

            if (typeof this.settings.animationShowAlert === 'function') {
                this.settings.animationShowAlert.call(this, alert);
            }

            alert.querySelector('.js-alert-message').innerHTML = message;

            if (alertClose) {
                alertClose.addEventListener('click', () => {
                    this.removeAlert(alert);
                });
            }
        },

        /**
         * @param {Object} alert
         */
        removeAlert(alert) {
            if (typeof this.settings.animationHideAlert === 'function') {
                this.settings.animationHideAlert.call(this, alert, () => {
                    alert.parentElement.removeChild(alert);
                });
            } else {
                alert.parentElement.removeChild(alert);
            }
        },

        /**
         * @param {Object} field
         */
        setValidState(field) {
            const fieldFeedback = field.querySelector(this.settings.fieldFeedbackSelector);
            field.classList.remove('is-invalid');
            field.classList.add('is-valid');

            if (typeof this.settings.animationHideFeedback === 'function') {
                this.settings.animationHideFeedback.call(this, fieldFeedback);
            } else {
                fieldFeedback.innerHTML = '';
            }
        },

        /**
         * @param {Object} field
         * @param {String} message
         */
        setInvalidState(field, message) {
            const fieldFeedback = field.querySelector(this.settings.fieldFeedbackSelector);
            field.classList.remove('is-valid');
            field.classList.add('is-invalid');
            if (message) {
                fieldFeedback.innerHTML = message;

                if (typeof this.settings.animationShowFeedback === 'function') {
                    this.settings.animationShowFeedback.call(this, fieldFeedback);
                }
            }
        },

        /**
         * @param {Object} control
         */
        validateControl(control) {
            const field = control.closest(this.settings.fieldSelector);
            const validity = control.validity;

            field.classList.remove('is-valid', 'is-invalid');

            // Ignore the control if it is hidden
            if (!(control.offsetWidth || control.offsetHeight || control.getClientRects().length)) {
                return;
            }

            if (validity.valid === false || control.getAttribute('data-validate-custom') || control.classList.contains('js-field-multicheck')) {
                let message = false;

                // Validation object should be checked in an arbitrary order, rather
                // than using a switch statement, so the user is presented with the
                // input problems in a logical order, firstly with required fields:
                if (validity.valueMissing === true) {
                    message = this.settings.localisation.validity.valueMissing;

                // Check email and URL regexes
                } else if (validity.typeMismatch === true && control.getAttribute('type') === 'email') {
                    message = this.settings.localisation.validity.typeMismatchEmail;
                } else if (validity.typeMismatch === true && control.getAttribute('type') === 'url') {
                    message = this.settings.localisation.validity.typeMismatchUrl;

                // Check input length
                } else if (validity.tooShort === true) {
                    message = this.settings.localisation.validity.tooShort.replace('$0', control.getAttribute('minlength'));
                } else if (validity.tooLong === true) {
                    message = this.settings.localisation.validity.tooLong.replace('$0', control.getAttribute('maxlength'));

                // Check numerical min/max values
                } else if (validity.rangeUnderflow === true) {
                    message = this.settings.localisation.validity.rangeUnderflow.replace('$0', control.getAttribute('min'));
                } else if (validity.rangeOverflow === true) {
                    message = this.settings.localisation.validity.rangeOverflow.replace('$0', control.getAttribute('max'));

                // Check against a regex
                } else if (validity.patternMismatch === true) {
                    if (control.getAttribute('data-pattern-message')) {
                        message = control.getAttribute('data-pattern-message');
                    } else {
                        message = this.settings.localisation.validity.patternMismatch;
                    }

                // Custom checks if the input is a multicheck
                } else if (control.classList.contains('js-field-multicheck')) {
                    const checks = this.form.querySelectorAll(`[name="${control.name}"]`);
                    let checkedCounter = 0;

                    for (let i = 0; i < checks.length; i++) {
                        if (checks[i].checked) {
                            ++checkedCounter;
                        }
                    }

                    if (field.getAttribute('data-validate-multicheck-min') && checkedCounter < parseInt(field.getAttribute('data-validate-multicheck-min'))) {
                        if (field.getAttribute('data-validate-multicheck-message')) {
                            message = field.getAttribute('data-validate-multicheck-message');
                        } else if (parseInt(field.getAttribute('data-validate-multicheck-min')) === 1) {
                            message = this.settings.localisation.validity.multicheckMissing;
                        } else {
                            message = this.settings.localisation.validity.multicheckMin.replace('$0', field.getAttribute('data-validate-multicheck-min'));
                        }
                    } else if (field.getAttribute('data-validate-multicheck-max') && checkedCounter > parseInt(field.getAttribute('data-validate-multicheck-max'))) {
                        if (field.getAttribute('data-validate-multicheck-message')) {
                            message = field.getAttribute('data-validate-multicheck-message');
                        } else {
                            message = this.settings.localisation.validity.multicheckMax.replace('$0', field.getAttribute('data-validate-multicheck-max'));
                        }
                    }

                // Finally, run a custom function passed as a data attribute (if present)
                } else if (this.settings.customValidations[control.getAttribute('data-validate-custom')](control.value) !== true) {
                    if (control.getAttribute('data-validate-custom-message')) {
                        message = control.getAttribute('data-validate-custom-message');
                    } else {
                        message = this.settings.localisation.validity.default;
                    }
                }

                // If a message has been set, the input is invalid
                if (message) {
                    control.setAttribute('aria-invalid', true);
                    this.setInvalidState(field, message);
                } else {
                    control.setAttribute('aria-invalid', false);
                    this.setValidState(field);
                }
            } else {
                this.setValidState(field);
            }
        },
    };

    window.Validate = Validate;

  })(window, document);
