export const navMain = () => {

  const navToggle = (target, item, list) => {

    if (!list.classList.contains('is-active')) {
      item.querySelector('[data-toggle="nav"]').setAttribute('aria-expanded', true);
    } else {
      item.querySelector('[data-toggle="nav"]').setAttribute('aria-expanded', false);
    }

    item.classList.toggle('is-open');
    list.classList.toggle('is-active');

    closeAll(target, item, list);

  };

  const closeAll = (target, item, list) => {

    let navButton = document.querySelector('[data-toggle="nav"]');
    let navList = document.querySelector('.main-menu__reveal');

    if (navButton !== target) {
      navButton.closest('.main-menu__item').classList.remove('is-open');
      navButton.setAttribute('aria-expanded', false);
    }

    if (navList !== list) {
      navList.classList.remove('is-active');
    }

  };

  document.addEventListener('click', function (event) {

    if (!event.target.matches('[data-toggle="nav"]')) return;

    const navItem = event.target.closest('.main-menu__item');
    const subNavList = navItem.querySelector('.main-menu__reveal');

    navToggle(event.target, navItem, subNavList);


  }, false);

};
